<template>
    <b-modal
        :id="id"
        size="sm"
        :title="title"
        ok-variant="info"
        centered
        :busy="busy"
        @ok="ok"
    >
        <slot> </slot>

        <template #modal-ok>
            <Spinner v-if="busy" small variant="light" />
            OK
        </template>
        <template #modal-cancel>Abbrechen</template>
    </b-modal>
</template>

<script>
import Spinner from "@/components/Spinner/Spinner";

export default {
    name: "ConfirmModal",
    components: {
        Spinner
    },
    props: {
        id: {
            type: String,
            default: "confirm-modal"
        },
        busy: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        }
    },
    methods: {
        ok(event) {
            this.$emit("ok", event);
        }
    }
};
</script>
