<template>
    <div class="persons-duplicate-list">
        <PersonsList
            :selectable="false"
            :fieldDefinition="fields"
            :showedFields="showedFields"
            sortBy="score"
            :sortDesc="true"
            :data="data"
            :dataBusy="busy"
        >
            <template #cell(score)="{data}">
                {{ data.value }}
            </template>

            <template #actions="data">
                <b-button
                    variant="outline-secondary"
                    size="sm"
                    class="mx-1"
                    :disabled="true"
                >
                    Aktion ausführen
                </b-button>
            </template>
        </PersonsList>
    </div>
</template>

<script>
import ApiFactory from "@/api/ApiFactory";

import Size from "@/mixins/Size/Size";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import PersonsList from "@/components/PersonsList/PersonsList";
import { mapActions, mapGetters } from "vuex";

const PersonsApi = ApiFactory.get("persons");

export default {
    name: "PersonsDuplicateList",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect,
        PersonsList
    },
    mixins: [Size],
    props: {
        query: {
            type: Object
        },
        filter: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            fields: [
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: false
                },
                {
                    key: "firstname",
                    label: "Vorname",
                    sortable: true
                },
                {
                    key: "lastname",
                    label: "Nachname",
                    sortable: true
                },
                {
                    key: "gender",
                    label: "Geschlecht",
                    sortable: true
                },
                {
                    key: "score",
                    label: "Wahrscheinlichkeit",
                    sortable: true
                },
                {
                    key: "verdict",
                    label: "Ähnlichkeit",
                    sortable: true
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            showedFields: [
                "id",
                "firstname",
                "lastname",
                "gender",
                "score",
                "verdict"
            ],
            data: [],
            page: 0,
            limit: 10
        };
    },
    computed: {
        dataAvailable() {
            return this.data.length > 0;
        },
        busy() {
            const busyPersonsDuplicate = this.$store.getters[
                "personsDuplicate/getBusy"
            ];
            return busyPersonsDuplicate.data;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
    },
    watch: {
        query: {
            handler() {
                this.fetch();
            }
        }
    },
    methods: {
        ...mapActions({
            fetchPersonsDuplicate: "personsDuplicate/fetchData"
        }),
        async fetch() {
            const query = this.query;
            const filter =
                this.filter.length > 0
                    ? this.filter.map(item => item.id)
                    : null;
            await this.fetchPersonsDuplicate({
                query,
                filter: {
                    id: filter
                },
                limit: this.limit,
                page: this.page
            });
            const duplicateData = this.$store.getters[
                "personsDuplicate/getData"
            ];
            const response = await await PersonsApi.get({
                id: duplicateData.map(item => item.id)
            });
            if (response.ok) {
                const data = response.json.data.map(item => {
                    const newItem = { ...item };
                    const scoreData = duplicateData.find(i => i.id == item.id);
                    if (scoreData) {
                        newItem.score = scoreData.score;
                        newItem.verdict = scoreData.prediction;
                    }
                    return newItem;
                });
                this.data = data;
            }
        }
    }
};
</script>
