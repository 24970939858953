var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"persons-list"},[_c('b-table',{ref:"selectableTable",attrs:{"items":_vm.records,"fields":_vm.currentSelectedFields,"filter":_vm.filter.pattern,"busy":_vm.busy.data,"small":_vm.size == 'sm',"select-mode":"multi","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"empty-text":"Keine Daten vorhanden","empty-filtered-text":"Für den gewählten Filter wurden keine Daten gefunden","foot-clone":"","no-footer-sorting":"","selectable":_vm.selectable,"bordered":"","striped":"","responsive":"","show-empty":"","tbody-tr-class":_vm.rowClass},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"thead-top",fn:function(ref){
var columns = ref.columns;
return [_c('b-tr',[(_vm.selectable)?_c('b-td',[_c('p',{staticClass:"small m-0"},[_vm._v("Gesamt: "+_vm._s(_vm.records.length))]),_c('p',{staticClass:"small m-0"},[_vm._v(" Gewählt: "),(_vm.selected.length > 0)?[_vm._v(_vm._s(_vm.selected.length)+" ")]:[_vm._v("-")]],2)]):_vm._e(),_c('b-td',{attrs:{"colspan":_vm.currentRecordFields.length}}),_c('b-td',{staticClass:"text-right"},[_c('b-dropdown',{attrs:{"variant":"outline-secondary","text":"Spaltenauswahl","size":_vm.size}},[_c('b-dropdown-form',[_c('b-form-checkbox-group',{attrs:{"options":_vm.recordFields,"value-field":"key","text-field":"label"},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}})],1)],1)],1)],1)]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"mr-1 align-middle",attrs:{"small":""}}),_c('strong',[_vm._v("Daten werden geladen...")])],1)]},proxy:true},{key:"head(__selected)",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectAll),expression:"selectAll"}],attrs:{"type":"checkbox","true-value":true,"false-value":false},domProps:{"checked":Array.isArray(_vm.selectAll)?_vm._i(_vm.selectAll,null)>-1:(_vm.selectAll)},on:{"change":[function($event){var $$a=_vm.selectAll,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selectAll=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selectAll=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selectAll=$$c}},_vm.toggleAllSelectedRows]}})]},proxy:true},{key:"cell(__selected)",fn:function(ref){
var rowSelected = ref.rowSelected;
return [_c('input',{attrs:{"type":"checkbox","true-value":true,"false-value":false,"disabled":""},domProps:{"checked":rowSelected}})]}},{key:"cell()",fn:function(data){return [_vm._t(("cell(" + (data.field.key) + ")"),[_vm._v(" "+_vm._s(data.value)+" ")],{"data":data})]}},{key:"cell(birthday)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.formatDate(data.value))+" ")]}},{key:"cell(phone)",fn:function(data){return _vm._l((data.item.contacts.filter(
                    function (item) { return item.type == 'phone'; }
                )),function(contact){return _c('p',{key:contact.id,staticClass:"my-0"},[_vm._v(" "+_vm._s(contact.value)+" "),_c('b-badge',[_vm._v(_vm._s(_vm.label(contact.label_id)))])],1)})}},{key:"cell(mobile)",fn:function(data){return _vm._l((data.item.contacts.filter(
                    function (item) { return item.type == 'mobile'; }
                )),function(contact){return _c('p',{key:contact.id,staticClass:"my-0"},[_vm._v(" "+_vm._s(contact.value)+" "),_c('b-badge',[_vm._v(_vm._s(_vm.label(contact.label_id)))])],1)})}},{key:"cell(email)",fn:function(data){return _vm._l((data.item.contacts.filter(
                    function (item) { return item.type == 'email'; }
                )),function(contact){return _c('p',{key:contact.id,staticClass:"my-0"},[_vm._v(" "+_vm._s(contact.value)+" "),_c('b-badge',[_vm._v(_vm._s(_vm.label(contact.label_id)))])],1)})}},{key:"cell(__actions)",fn:function(data){return [_vm._t("actions",[_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.editAction({
                            record: data.item,
                            mode: 'edit'
                        })}}},[_vm._v("Bearbeiten")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return _vm.deleteAction({ record: data.item })}}},[_vm._v("Löschen")]),_c('b-button',{staticClass:"mx-1",attrs:{"variant":"outline-secondary","size":"sm"},on:{"click":function($event){return _vm.duplicateDetectionAction(data.item)}}},[_vm._v(" Duplikate erkennen ")])],{"data":data})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }