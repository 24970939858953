<template>
    <div class="persons-edit-view">
        <FormGroupBuilder :fields="localIoFields" :data="data" />
    </div>
</template>

<script>
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";

export default {
    name: "PersonsEditView",
    components: { FormGroupBuilder },
    props: {
        data: {
            type: Object
        },
        busy: {
            type: Boolean,
            default: false
        },
        allowedIoFields: {
            type: Array,
            default: () => {
                return [
                    "id",
                    "title",
                    "firstname",
                    "lastname",
                    "gender",
                    "birthday",
                    "birth_place",
                    "origin_country",
                    "account_iban"
                ];
            }
        },
        ioFields: {
            type: Object,
            default: () => {
                return {
                    id: {
                        key: "id",
                        label: "ID",
                        disabled: true
                    },
                    title: {
                        key: "title",
                        label: "Titel"
                    },
                    firstname: {
                        key: "firstname",
                        label: "Vorname"
                    },
                    lastname: {
                        key: "lastname",
                        label: "Nachname"
                    },
                    gender: {
                        key: "gender",
                        label: "Geschlecht",
                        type: "select",
                        options: [
                            { value: "d", text: "divers" },
                            { value: "w", text: "weiblich" },
                            { value: "m", text: "männlich" }
                        ]
                    },
                    birthday: {
                        key: "birthday",
                        label: "Geburtstag",
                        type: "date"
                    },
                    birth_place: {
                        key: "birth_place",
                        label: "Geburtsort"
                    },
                    origin_country: {
                        key: "origin_country",
                        label: "Herkunftsland"
                    },
                    account_iban: {
                        key: "account_iban",
                        label: "Kontodaten (IBAN)"
                    }
                };
            }
        }
    },
    data() {
        return {};
    },
    computed: {
        localIoFields() {
            return Object.values(this.ioFields).filter(item =>
                this.allowedIoFields.includes(item.key)
            );
        }
    },
    watch: {
        busy: {
            handler(val) {}
        }
    }
};
</script>
