export default {
    methods: {
        formatDate(date) {
            const value = this.$moment(date);
            return value.isValid() ? value.format("DD.MM.Y") : "";
        },
        formatDateTime(datetime) {
            const value = this.$moment(datetime);
            return value.isValid() ? value.format("DD.MM.Y HH:mm:ss") : "";
        }
    }
};
