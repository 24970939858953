<template>
    <div class="persons-list">
        <b-table
            :items="records"
            :fields="currentSelectedFields"
            :filter="filter.pattern"
            :busy="busy.data"
            :small="size == 'sm'"
            select-mode="multi"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            ref="selectableTable"
            empty-text="Keine Daten vorhanden"
            empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
            foot-clone
            no-footer-sorting
            :selectable="selectable"
            bordered
            striped
            responsive
            show-empty
            :tbody-tr-class="rowClass"
            @row-selected="onRowSelected"
        >
            <template #thead-top="{ columns }">
                <b-tr>
                    <b-td v-if="selectable">
                        <p class="small m-0">Gesamt: {{ records.length }}</p>
                        <p class="small m-0">
                            Gewählt:
                            <template v-if="selected.length > 0"
                                >{{ selected.length }}
                            </template>
                            <template v-else>-</template>
                        </p>
                    </b-td>
                    <b-td :colspan="currentRecordFields.length"></b-td>
                    <b-td class="text-right"
                        ><b-dropdown
                            variant="outline-secondary"
                            text="Spaltenauswahl"
                            :size="size"
                        >
                            <b-dropdown-form>
                                <b-form-checkbox-group
                                    v-model="selectedFields"
                                    :options="recordFields"
                                    value-field="key"
                                    text-field="label"
                                ></b-form-checkbox-group>
                            </b-dropdown-form>
                        </b-dropdown>
                    </b-td>
                </b-tr>
            </template>

            <template #table-busy>
                <div class="text-center text-secondary my-2">
                    <b-spinner class="mr-1 align-middle" small></b-spinner>
                    <strong>Daten werden geladen...</strong>
                </div>
            </template>

            <template #head(__selected)>
                <input
                    type="checkbox"
                    v-model="selectAll"
                    :true-value="true"
                    :false-value="false"
                    @change="toggleAllSelectedRows"
                />
            </template>

            <template #cell(__selected)="{ rowSelected }">
                <input
                    type="checkbox"
                    :checked="rowSelected"
                    :true-value="true"
                    :false-value="false"
                    disabled
                />
            </template>

            <template #cell()="data">
                <slot :name="`cell(${data.field.key})`" v-bind:data="data">
                    {{ data.value }}
                </slot>
            </template>

            <template #cell(birthday)="data">
                {{ formatDate(data.value) }}
            </template>

            <template #cell(phone)="data">
                <p
                    class="my-0"
                    v-for="contact in data.item.contacts.filter(
                        item => item.type == 'phone'
                    )"
                    :key="contact.id"
                >
                    {{ contact.value }}
                    <b-badge>{{ label(contact.label_id) }}</b-badge>
                </p>
            </template>

            <template #cell(mobile)="data">
                <p
                    class="my-0"
                    v-for="contact in data.item.contacts.filter(
                        item => item.type == 'mobile'
                    )"
                    :key="contact.id"
                >
                    {{ contact.value }}
                    <b-badge>{{ label(contact.label_id) }}</b-badge>
                </p>
            </template>

            <template #cell(email)="data">
                <p
                    class="my-0"
                    v-for="contact in data.item.contacts.filter(
                        item => item.type == 'email'
                    )"
                    :key="contact.id"
                >
                    {{ contact.value }}
                    <b-badge>{{ label(contact.label_id) }}</b-badge>
                </p>
            </template>

            <template #cell(__actions)="data">
                <slot name="actions" v-bind:data="data">
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        @click="
                            editAction({
                                record: data.item,
                                mode: 'edit'
                            })
                        "
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="deleteAction({ record: data.item })"
                        >Löschen</b-button
                    >
                    <b-button
                        variant="outline-secondary"
                        size="sm"
                        class="mx-1"
                        @click="duplicateDetectionAction(data.item)"
                    >
                        Duplikate erkennen
                    </b-button>
                </slot>
            </template>
        </b-table>
    </div>
</template>

<script>
import ApiFactory from "@/api/ApiFactory";

import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import { mapActions, mapGetters } from "vuex";

const PersonsApi = ApiFactory.get("persons");

export default {
    name: "PersonsList",
    props: {
        selectable: {
            type: Boolean,
            default: true
        },
        fieldDefinition: {
            type: Array,
            default: () => {
                return [
                    {
                        key: "__selected",
                        label: "",
                        class: "text-center",
                        static: true,
                        position: "prepend"
                    },
                    {
                        key: "id",
                        label: "ID",
                        sortable: true
                    },
                    {
                        key: "title",
                        label: "Titel",
                        sortable: false
                    },
                    {
                        key: "firstname",
                        label: "Vorname",
                        sortable: true
                    },
                    {
                        key: "lastname",
                        label: "Nachname",
                        sortable: true
                    },
                    {
                        key: "gender",
                        label: "Geschlecht",
                        sortable: true
                    },
                    {
                        key: "birthday",
                        label: "Geburtstag",
                        sortable: true
                    },
                    {
                        key: "birth_place",
                        label: "Geburtsort",
                        sortable: true
                    },
                    {
                        key: "origin_country",
                        label: "Herkunftsland",
                        sortable: true
                    },
                    {
                        key: "account_iban",
                        label: "IBAN",
                        sortable: true
                    },
                    {
                        key: "__actions",
                        label: "Aktionen",
                        class: "text-right",
                        static: true,
                        position: "append"
                    }
                ];
            }
        },
        showedFields: {
            type: Array,
            default: () => {
                return [
                    "id",
                    "title",
                    "firstname",
                    "lastname",
                    "gender",
                    "birthday",
                    "birth_place",
                    "origin_country"
                ];
            }
        },
        filter: {
            type: Object,
            default: () => {
                return {
                    pattern: null
                };
            }
        },
        filterUpdate: {
            type: Boolean,
            default: false
        },
        externalFetchMode: {
            type: Boolean,
            default: false
        },
        sortBy: {
            type: String,
            default: "lastname"
        },
        sortDesc: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: null
        },
        dataBusy: {
            type: Boolean,
            default: false
        },
        additionalData: {
            type: Array,
            default: () => {
                return [];
            }
        },
        rowClass: {
            type: Function,
            default: () => {
                return;
            }
        }
    },
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size, DateFormat],
    data() {
        return {
            fields: this.fieldDefinition,
            selectedFields: this.showedFields,
            recordsFromExternal: [],
            busyFromExternal: {
                data: false
            }
        };
    },
    computed: {
        ...mapGetters({
            busyFromStore: "persons/getBusy",
            recordsFromStore: "persons/getData",
            storedContactTypes: "contactTypes/getData",
            label: "label/getName"
        }),
        dataAvailable() {
            return this.records.length > 0;
        },
        busy() {
            if (this.data) {
                return { data: this.dataBusy };
            } else if (this.externalFetchMode) {
                return this.busyFromExternal;
            } else {
                return this.busyFromStore;
            }
        },
        records: {
            get() {
                var data = [];
                if (this.data) {
                    data = this.data;
                } else if (this.externalFetchMode) {
                    data = this.recordsFromExternal;
                } else {
                    data = this.recordsFromStore;
                }
                if (
                    this.copiedAdditionalData &&
                    this.copiedAdditionalData.length > 0
                ) {
                    data = data.map(item => {
                        const itemAdditionalData = this.copiedAdditionalData.find(
                            i => i.persons_id == item.id
                        );
                        if (itemAdditionalData) {
                            const disallowed = [
                                "modification_id",
                                "modification_date",
                                "modification_author",
                                "deleted"
                            ];

                            const filteredItemAdditionalData = Object.keys(
                                itemAdditionalData
                            )
                                .filter(key => !disallowed.includes(key))
                                .reduce((obj, key) => {
                                    obj[key] = itemAdditionalData[key];
                                    return obj;
                                }, {});

                            item = Object.assign(
                                item,
                                filteredItemAdditionalData
                            );
                        }
                        return item;
                    });
                }
                return data;
            },
            set(value) {
                if (!this.externalFetchMode && !this.data) {
                    this.recordsFromStore = value;
                }
            }
        },
        recordFields() {
            return this.fields
                .filter(field => !field.static)
                .concat(this.contactFields);
        },
        currentSelectedFields() {
            const staticFields = this.staticFields;
            const recordFields = this.currentRecordFields;
            const contactFields = this.currentContactFields;
            return staticFields
                .filter(field => field.position == "prepend")
                .concat(recordFields)
                .concat(contactFields)
                .concat(
                    staticFields.filter(field => field.position == "append")
                );
        },
        currentContactFields() {
            return this.recordFields.filter(field =>
                this.selectedFields.includes(field.key)
            );
        },
        contactFields() {
            return this.storedContactTypes.map(item => {
                const value = {
                    key: item.value,
                    label: item.text
                };
                return value;
            });
        },
        copiedAdditionalData() {
            return JSON.parse(JSON.stringify(this.additionalData));
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchLabel();
    },
    watch: {
        dataAvailable: {
            handler(val) {
                this.$emit("updateDataAvailable", val);
            },
            immediate: true
        },
        currentRecordFields: {
            handler(val) {
                this.$emit("updateFields", val);
            },
            immediate: true,
            deep: true
        },
        selectable: {
            handler(val) {
                if (val === false) {
                    this.fields = this.fields.filter(
                        item => item.key !== "__selected"
                    );
                }
            },
            immediate: true
        },
        showedFields: {
            handler(val) {
                this.selectedFields = this.selectedFields.filter(item =>
                    val.includes(item)
                );
            },
            immediate: true
        },
        filterUpdate: {
            handler(val) {
                this.applyFilter();
            }
        },
        selected: {
            handler(val) {
                this.$emit("updateSelected", val);
            }
        }
    },
    methods: {
        ...mapActions({
            fetchPersonsFromStore: "persons/fetchData",
            fetchLabel: "label/fetchData"
        }),
        fetch(payload) {
            if (!this.data) {
                if (this.externalFetchMode) {
                    this.fetchPersonsExternal(payload);
                } else {
                    this.fetchPersonsFromStore(payload);
                }
            }
        },
        async fetchPersonsExternal(payload) {
            this.busyFromExternal.data = true;
            const filter = payload && payload.filter ? payload.filter : null;

            const response = await PersonsApi.get(filter);
            if (response.ok) {
                this.recordsFromExternal = response.json.data ?? [];
            }
            this.busyFromExternal.data = false;
        },
        applyFilter() {
            this.fetch({ filter: this.filter });
        },
        editAction(data) {
            this.$emit("edit", data);
        },
        deleteAction(data) {
            this.$emit("delete", data);
        },
        duplicateDetectionAction(data) {
            this.$emit("duplicate", data);
        }
    }
};
</script>
