<template>
    <MainContainer>
        <div class="persons">
            <h2>Kontakte</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <!--<h6>VHS</h6>
                            <CheckboxSelect
                                :options="vhsSelect"
                                v-model="filter.vhs"
                                :size="size"
                            />-->
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>

                    <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="openModificationModal({ mode: 'create' })"
                        >Neuer Kontakt</b-button
                    >
                    <b-button
                        class="mx-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                    <hr />
                    <h6>Export</h6>
                    <TableExport
                        :busy="busy"
                        :dataAvailable="dataAvailable"
                        @exportExcel="exportExcel"
                        @exportPDF="exportPDF"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col md="9" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>

            <PersonsList
                :filter="filter"
                @edit="openModificationModal"
                @delete="openDeleteModal"
                @updateDataAvailable="updateDataAvailable"
                @updateFields="updateFields"
                @updateSelected="updateSelected"
                @duplicate="duplicateDetectionAction"
            />

            <EditModal
                :busy="busy.mutation"
                :mode="modificationMode"
                size="xl"
                @ok="handleModification"
                @hidden="hideModificationModal"
            >
                <b-tabs pills card vertical>
                    <b-tab title="Stammdaten" active>
                        <PersonsEditView
                            :allowedIoFields="allowedPersonsIoFields"
                            :data="modificationRecord"
                        />
                    </b-tab>
                    <b-tab title="Kontaktdaten">
                        <PersonContactView :data="modificationRecord" />
                    </b-tab>
                </b-tabs>
            </EditModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="fields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>

            <b-modal id="duplicateModal" title="Duplikaterkennung" size="xl">
                <PersonsDuplicateList
                    :query="duplicateQuery"
                    :filter="selected"
                />
            </b-modal>
        </div>
    </MainContainer>
</template>

<script>
import MainContainer from "@/components/MainContainer/MainContainer";
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import Date from "@/mixins/Date/Date";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import EditModal from "@/components/EditModal/EditModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import PersonsList from "@/components/PersonsList/PersonsList";
import PersonsEditView from "@/components/PersonsEditView/PersonsEditView";
import PersonContactView from "@/components/PersonContactView/PersonContactView";
import PersonsDuplicateList from "@/components/PersonsDuplicateList/PersonsDuplicateList";

import { mapActions, mapGetters } from "vuex";

export default {
    name: "Persons",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        EditModal,
        DeleteModal,
        CheckboxSelect,
        PersonsList,
        PersonsEditView,
        PersonContactView,
        PersonsDuplicateList
    },
    mixins: [ModelView, Size, Date],
    data() {
        return {
            filter: {
                pattern: null,
                vhs: []
            },
            fields: [],
            allowedPersonsIoFields: [
                "id",
                "title",
                "firstname",
                "lastname",
                "gender",
                "birthday",
                "birth_place",
                "origin_country",
                "account_iban"
            ],
            actions: {
                create: "persons/createData",
                edit: "persons/editData",
                delete: "persons/deleteData",
                deleteMulti: "persons/deleteMultipleData"
            },
            dataAvailable: false,
            duplicateQuery: null
        };
    },
    computed: {
        ...mapGetters({
            busy: "persons/getBusy",
            records: "persons/getData"
        })
    },
    created() {},
    watch: {},
    methods: {
        async exportExcel(format) {
            this.$store.dispatch("persons/exportExcel", {
                format,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        async exportPDF(orientation = "P") {
            this.$store.dispatch("persons/exportPDF", {
                orientation,
                filter: this.filter,
                selectedFields: this.selectedFields,
                selectedData: this._discardSelectedData(this.selected, "id")
            });
        },
        applyFilter() {
            this.fetch({ filter: this.filter });
        },
        updateDataAvailable(value) {
            this.dataAvailable = value;
        },
        updateFields(value) {
            this.fields = value;
        },
        updateSelected(value) {
            this.selected = value;
        },
        duplicateDetectionAction(data) {
            this.$bvModal.show("duplicateModal");
            this.duplicateQuery = data;
        },
        hideModificationModal() {
            this.modificationRecord = null;
        }
    }
};
</script>
